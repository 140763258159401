import React from "react";
import Layout from "../../components/layout";
import CapabilitiesHeroSection from "../../components/capabilitiesherosection";
import AuditButtons from "../../components/auditbuttons";
import SingleButton from "../../components/singlebutton";
import { Helmet } from "react-helmet";
import Audits from "../../components/audits";
import Arrow from "../../components/arrow";

import capabilityicon from "../../images/capability-social-2.png";

import socialstrategy from "../../images/digital-audits.png";
import socialcontentcalendars from "../../images/digital-media-planning.png";
import contentproduction from "../../images/content-creation.png";
import socialplatformmanagement from "../../images/social-platform-management.png";
import communitymanagement from "../../images/community-management.png";
import paidsocialadvertising from "../../images/paid-social-advertising.png";
import socialmediatraining from "../../images/social-media-training.png";

import rowtwoheroleft from "../../images/social-row-two-hero-left.jpg";
import rowtwoheroright from "../../images/social-row-two-hero-right.jpg";

import rowfivehero1 from "../../images/social-row-five-hero-1.png";
import rowfivehero2 from "../../images/social-row-five-hero-2.png";

import rowsixhero1 from "../../images/social-row-six-hero-1.png";
import rowsixhero2 from "../../images/social-row-six-hero-2.png";
import rowsixhero3 from "../../images/social-row-six-hero-3.png";

import "../../scss/capabilitypage.scss";

const Social = ({ data }) => {
    const metadescription = "Next&Co's social media marketing services harness the power of communities for your brand with measurable value, visit our site or call 1300 191 950";
    const metatitle = "Social Media Marketing Agency Melbourne - Next&Co";
    const metaurl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet>
                <title>{metatitle}</title>
                <meta name="description" content={metadescription}/>
                <meta name="title" content={metatitle} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={metaurl} />
                <meta property="og:title" content={metatitle} />
                <meta property="og:description" content={metadescription} />
                <meta property="og:image" content="facebook.png" />
                <meta property="twitter:url" content={metaurl} />
                <meta property="twitter:title" content={metatitle} />
                <meta property="twitter:description" content={metadescription} />
                <meta property="twitter:image" content="twitter.png" />
            </Helmet>
            <Arrow/>
            <div className="capability-page-general social-page">
                <Layout>
                    <CapabilitiesHeroSection
                        heading="Social"
                        description="Social - Harnessing the power of communities for your brand"
                        icon={capabilityicon}
                        imagedesktop={data.desktop.childImageSharp.fluid}
                        imagemobile={data.mobile.childImageSharp.fluid}
                    />
                    <section id="row-three">
                        <div className="outer-container">
                            <div className="container-max-width">
                                <div className="inner-container row">
                                    <div className="col-one">
                                        <h4 className="col-onef-heading">What we do</h4>
                                    </div>
                                    <div className="col-one">
                                        <div className="list-items">
                                            <div className="list-item">
                                                <span className="icon">
                                                    <img alt="" src={socialstrategy}/>
                                                </span>
                                                <div className="content">
                                                    <h3>Social strategy</h3>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                <span className="icon">
                                                    <img alt="" src={socialcontentcalendars} />
                                                </span>
                                                <div className="content">
                                                    <h3>Social content <br/> calendars</h3>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                <span className="icon">
                                                    <img alt="" src={contentproduction} />
                                                </span>
                                                <div className="content">
                                                    <h3>Content production</h3>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                <span className="icon">
                                                    <img alt="" src={socialplatformmanagement} />
                                                </span>
                                                <div className="content">
                                                    <h3>Social platform <br/> management</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-one">
                                        <div className="list-items">
                                            <div className="list-item">
                                                <span className="icon">
                                                    <img alt="" src={communitymanagement} />
                                                </span>
                                                <div className="content">
                                                    <h3>Community <br/> management</h3>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                <span className="icon">
                                                    <img alt="" src={paidsocialadvertising} style={{maxWidth: `30px`}} />
                                                </span>
                                                <div className="content">
                                                    <h3>Paid social <br/> advertising</h3>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                <span className="icon">
                                                    <img alt="" src={socialmediatraining} />
                                                </span>
                                                <div className="content">
                                                    <h3>Social media <br/> training</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <AuditButtons
                        title1="Let’s talk about your social media."
                        linktitle1="Get in touch"
                        link1="#"
                    />
                    <section id="row-two">
                        <div className="outer-container">
                            <div className="container-max-width">
                                <div className="row">
                                    <div className="col">
                                        <img alt="" src={rowtwoheroleft} />
                                    </div>
                                    <div className="col">
                                        <img alt="" src={rowtwoheroright} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="row-five">
                        <div className="outer-container">
                            <div className="container-max-width">
                                <div className="inner-container">
                                    <h4>Brands becoming more human</h4>
                                    <p>Brand’s have the opportunity to use social media to reflect the interests, attitudes and opinions of the customers they serve. Next&amp;Co want to work with brands who want to communicate with their customers; becoming relevant to your consumers’ lifestyles beyond a mere functional level.</p>
                                    <div className="row">
                                        <div className="col">
                                            <img alt="" src={rowfivehero1} />
                                        </div>
                                        <div className="col">
                                            <img alt="" src={rowfivehero2} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="row-six">
                        <div className="outer-container">
                            <div className="container-max-width">
                                <div className="inner-container">
                                    <h4>Our approach to social</h4>
                                    <div className="row">
                                        <div className="col">
                                            <img alt="" src={rowsixhero1} />
                                        </div>
                                        <div className="col">
                                            <img alt="" src={rowsixhero2} />
                                        </div>
                                        <div className="col">
                                            <img alt="" src={rowsixhero3} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="row-four">
                        <div className="outer-container">
                            <div className="container-max-width">
                                <div className="inner-container">
                                    <div className="col-one">
                                        <h3>Many of Australia's leading businesses partner with us, you should too.</h3>
                                        <SingleButton buttonText="Get in touch"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Audits />
                </Layout>
            </div>
        </>
    );
};

export default Social;

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "capabilities-desktop-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    mobile: file(relativePath: { eq: "capabilities-mobile-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
